import styles from './Page.module.css'

interface PageProps {
  title: string
  titleRightElement?: React.ReactNode
  children: React.ReactNode
}

export const Page: React.FC<PageProps> = ({
  title,
  children,
  titleRightElement,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading}>{title}</h1>
          {titleRightElement}
        </div>
        {children}
      </div>
    </div>
  )
}
